import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import style from './ScheduleCollectionManagement.module.scss';
import Divider from '../../../components/Divider/Divider';
import { IToastMessage, Tab } from '../../../interface';
import ToastMessage from '../../../components/ToastMessage/ToastMessage';
import DepreciationCollectionManagementTable from '../../../components/DepreciationCollectionManagementTable/DepreciationCollectionManagementTable';
import Tabs from '../../../components/Tabs/Tabs';
import { useSearchParams } from 'react-router-dom';
import _ from 'lodash';
import IndexCollectionManagementTable from '../../../components/IndexCollectionManagementTable/IndexCollectionManagementTable';

interface Props {
  className?: string;
}

function ScheduleCollectionManagement({ className }: Props) {
  const [toastMessage, setToastMessage] = useState<IToastMessage>();
  const [searchParams, setSearchParams] = useSearchParams();
  
  const tabs: Tab[] = [
    { name: 'Depreciation Schedule Collections'},
    { name: 'Index Table Collections'},
  ];
  const [selectedTab, setSelectedTab] = useState<string>(searchParams.get('tab') as string);

  useEffect(() => {
    switch (searchParams.get('tab')) {
      case 'Index Table Collections':
        setSelectedTab(tabs[1].name);
        break;
      default:
        setSelectedTab(tabs[0].name);
        break;
    }
  }, []);

  useEffect(() => {
    setSearchParams(`?tab=${selectedTab}`, {replace: true});
  }, [selectedTab]);

  return (
    <div className={
      classNames(
        className,
        style['main-content'],
        'DepreciationCollectionManagement d-flex flex-column')
    }>
      {toastMessage?.visible && (
        <div className={style.toast}>
          <ToastMessage
            className='successful'
            status={toastMessage}
            visiableHandler={(value) =>
              setToastMessage({ ...toastMessage, visible: value })
            }
          />
        </div>
      )}

      <Divider className={style.divider1} />

      <Tabs tabs={tabs} selected={selectedTab} onSelect={setSelectedTab}>
        {_.isEqual(selectedTab, tabs[1].name) ? (
          <IndexCollectionManagementTable />
        ) : (
          <DepreciationCollectionManagementTable />
        )}
        
      </Tabs>
      

    </div>
  );
}

export default ScheduleCollectionManagement;