import _, {entries, last, range} from 'lodash';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {connect} from 'react-redux';
import BaseModal from '../../../components/BaseModal/BaseModal';
import Loader from '../../../components/Loader/Loader';
import Modal from '../../../components/Modal/Modal';
import PurchaseReturnForm from '../../../components/PurchaseReturnForm/PurchaseReturnForm';
import InitTable, {Query} from '../../../components/Table/Table';
import ToastMessage, {
  ToastMessageRefType,
} from '../../../components/ToastMessage/ToastMessage';
import {PurchaseReturn, PurchaseReturnResponse, PurchaseReturnUI} from '../../../interface';
import {
  AsyncStatus,
  getPurchasedReturnList,
  updatePurchasedReturnToStore,
} from '../../../redux/features/admin-slice';
import {RootState, useAppDispatch} from '../../../redux/store';
import {
  deletePurchasedReturns,
  getErrorMessage,
  getPurchasedReturn,
  getSystemTaxYears,
  updatePurchasedReturns,
} from '../../../services';
import style from './PurchasedReturn.module.scss';
import moment from 'moment';

const {Table, Column} = InitTable<PurchaseReturnData>();

interface PurchaseReturnData {
  id?: number;
  fullName: string;
  freeTrialStartDate: string;
  businessName: string;
  years: Map<number, number>;
}

interface Props {
  purchasedReturn: PurchaseReturnResponse;
  fetchStatus?: AsyncStatus;
}

function PurchasedReturn(props: Props) {
  const {purchasedReturn, fetchStatus} = props;
  const toastRef = useRef<ToastMessageRefType | null>(null);

  const dashboardDispatch = useAppDispatch();

  // Search input field
  const currentCalendarYear = moment().year();
  const [fetchingAllTaxYears, setFetchingAllTaxYears] = useState(false);
  const [currentTaxYear, setCurrentTaxYear] = useState<number>(currentCalendarYear);
  const [toBeDeleted, setToBeDeleted] = useState<PurchaseReturnData>();
  const [editableData, setEditableData] = useState<PurchaseReturnUI>();
  const [query, setQuery] = useState<Query>();

  // Component initialization, fetch system tax years
  useEffect(() => {
    setFetchingAllTaxYears(true);
    getSystemTaxYears()
      .then((res) => {
        setFetchingAllTaxYears(false);
        const allActiveTaxYears = _.orderBy(
          res.data,
          ['taxYear'],
          ['desc'],
        );

        let currentTaxYearIndex = _.findIndex(allActiveTaxYears, {
          isCurrent: true,
        });
        if (currentTaxYearIndex < 0) {
          currentTaxYearIndex = 0;
        }
        setCurrentTaxYear(allActiveTaxYears[currentTaxYearIndex].taxYear);
      })
      .catch(() => {
        setFetchingAllTaxYears(false);
      });
  }, []);

  // Display year columns for 3 years before and 1 year after current tax year
  const years = useMemo(
    () => range(currentTaxYear - 3, currentTaxYear + 2),
    [currentTaxYear],
  );

  const handleDelete = (value: PurchaseReturnData) => {
    setToBeDeleted(value);
  };

  const constructPurchaseData = (arr: PurchaseReturn[]) => {
    const res = arr.map((item: PurchaseReturn) => {
      return {
        id: item.id,
        fullName: `${item.firstName} ${item.lastName}`,
        freeTrialStartDate: `${item.freeTrialStartDate ? item.freeTrialStartDate : ''}${
          item.freeTrialStartDate ? ' - ' : ''
        }${item.freeTrialEndDate ? item.freeTrialEndDate : ''}`,
        businessName: item.businessName,
        years: new Map(
          item.userPurchases.map((y) => [y.taxYear, y.returnsPurchased]),
        ),
      } as PurchaseReturnData;
    });

    return res;
  };

  const queryPurchasedReturnList = (queryVal: Query | undefined) => {
    if (queryVal) {
      const sort = last(entries(queryVal?.order));

      dashboardDispatch(
        getPurchasedReturnList({
          page: queryVal.pagination?.page ?? 1,
          perPage: queryVal.pagination?.pageSize ?? 10,
          sortBy: sort ? sort[0] : '',
          sortDirection: sort ? sort[1] : '',
          text: queryVal.search ?? '',
        }),
      );
    }
  };

  useEffect(() => {
    queryPurchasedReturnList(query)
  }, [query]);

  const data = useMemo(
    () => constructPurchaseData(purchasedReturn.items),
    [purchasedReturn, query?.search, query?.filter, query?.order],
  );
  const [submitting, setSubmitting] = useState(false);
  const [updateErrorMessage, setUpdateErrorMessage] = useState('');

  const handleSubmit = (entry: PurchaseReturnUI) => {
    setSubmitting(true);
    setUpdateErrorMessage('');
    updatePurchasedReturns(entry.id || 0, {
      businessName: entry.businessName,
      email: entry.email,
      firstName: entry.firstName,
      lastName: entry.lastName,
      freeTrialStartDate: entry.freeTrialStartDate,
      freeTrialEndDate: entry.freeTrialEndDate,
      userPurchases: entry.userPurchases.map((item) => ({
        id: item.id,
        taxYear: item.taxYear,
        returnsPurchased: item.returnsPurchased,
      })),
      roles: entry.roles
    })
      .then((resp) => {
        const errorMessage = getErrorMessage(resp);
        if (errorMessage) {
          toastRef.current?.showErrorToast(errorMessage);
        } else {
          toastRef.current?.showSuccessToast('Update successfully');
          dashboardDispatch(updatePurchasedReturnToStore(resp.data));
        }
        setSubmitting(false);
        setEditableData(undefined);
      })
      .catch((err) => {
        setSubmitting(false);
        const errMessage = getErrorMessage(err);
        if (errMessage) {
          setUpdateErrorMessage(errMessage);
        } else {
          setEditableData(undefined);
        }
      });
  };

  const [impersonateModalOpen, setImpersonateModalOpen] = useState<boolean>(false);
  const [impersonateId, setImpersonateId] = useState<PurchaseReturnData>();
  const impersonate = (item: PurchaseReturnData) => {
    setImpersonateModalOpen(true);
    setImpersonateId(item);
  };

  const keepImpersonate = () => {
    if (impersonateId?.id) {
      sessionStorage.setItem('user-account-id', _.toString(impersonateId.id));
      sessionStorage.removeItem('skip');
      window.location.href = '/dashboard';
    }
  };

  const closeImpersonateComfirm = () => {
    setImpersonateModalOpen(false);
    setImpersonateId(undefined);
  };

  const getTaxYearKey = useCallback((val: number): string => {
    const lastYear = years[years.length - 1];
    return 'taxYear' + (lastYear - val);
  }, [years]);

  return (
    <>
      <div className={style['main-content']}>
        {/* Braedcrumb */}
        <h3>Purchased Returns</h3>
        <ToastMessage ref={toastRef} className={style['toast']} />

        <Table
          id='purchasedReturns'
          rows={data}
          onQueryChanged={setQuery}
          paginate
          searchable='Search purchased returns...'
          totalRows={purchasedReturn.total}
          loading={false}
        >
          <Column label='Customer Name' prop='fullName' sortable />
          <Column label='Free Trial Period' prop='freeTrialStartDate' sortable />
          <Column label='Business Name' prop='businessName' sortable />

          {years.map((val) => (
            <Column
              key={val}
              id={getTaxYearKey(val)}
              label={String(val)}
              accessor={(item) => item.years.get(val) ?? null}
              sortable
            />
          ))}

          <Column id='actions' label='Actions'>
            {(item: PurchaseReturnData) => (
              <div className='operate'>
                <i
                  role='button'
                  className='edit'
                  onClick={async () => {
                    const editable = purchasedReturn.items.find(
                      (x) => x.id === item?.id,
                    );
                    const res = await getPurchasedReturn(item?.id as number);
                    if (editable) {
                      setEditableData({...editable, roles: res.data.roles});
                    }
                  }}
                ></i>
                <i
                  role='button'
                  className='delete'
                  onClick={() => handleDelete(item)}
                ></i>
                <i
                  role='button'
                  className='impersonate'
                  onClick={() => impersonate(item)}
                ></i>
              </div>
            )}
          </Column>
        </Table>
      </div>

      <Modal
        title='Confirmation'
        body={<p>Are you sure you want to delete?</p>}
        isOpen={!!toBeDeleted}
        footer={
          <div className='buttons'>
            <button
              className='primary-button'
              onClick={() => {
                const item = purchasedReturn.items.find(
                  (x) => x.id === toBeDeleted?.id,
                );
                if (item && item.id) {
                  deletePurchasedReturns(item.id).then((e) => {
                    const errorMessage = getErrorMessage(e);
                    if (errorMessage) {
                      toastRef.current?.showErrorToast(errorMessage);
                    } else {
                      toastRef.current?.showSuccessToast('Delete successfully');
                    }
                    if (purchasedReturn.items.length > 1) {
                      queryPurchasedReturnList(query);
                    }
                  });
                }
                setToBeDeleted(undefined);
              }}
            >
              Confirm
            </button>
            <button
              className='default-button'
              onClick={() => setToBeDeleted(undefined)}
            >
              Cancel
            </button>
          </div>
        }
      />
      <BaseModal isOpen={!!editableData} className={style['modal']}>
        <div className={style['modal-wrapper']}>
          <h3>Edit User</h3>
          {editableData && (
            <PurchaseReturnForm
              inEditMode
              data={editableData}
              onSubmit={handleSubmit}
              submitting={submitting}
              sectionClassName={style['section']}
              formClassName={style['form']}
              onClear={() => {
                setEditableData(undefined);
              }}
              innerModal={true}
              errorMessage={updateErrorMessage}
            />
          )}
        </div>
      </BaseModal>

      <Modal
        className='impersonate-modal'
        title='Confirmation'
        body={
          <p>
            Any changes made while using impersonate feature will be tracked as
            system admin action, do you want to keep continue?
          </p>
        }
        isOpen={impersonateModalOpen}
        footer={
          <div className='buttons'>
            <button className='primary' onClick={keepImpersonate}>
              Yes
            </button>
            <button
              className='secondary'
              onClick={closeImpersonateComfirm}
            >
              No
            </button>
          </div>
        }
      />

      <Loader isOpen={
        fetchStatus === 'pending' ||
        fetchingAllTaxYears
      }/>
    </>
  );
}

const mapStateToProps = (state: RootState) => ({
  fetchStatus: state.admin?.fetchStatus,
  purchasedReturn: state.admin?.purchasedReturn,
});

export default connect(mapStateToProps)(PurchasedReturn);
