import React, { useState } from 'react';
import classNames from 'classnames';
import style from './AssessorManagement.module.scss';
import Divider from '../../../components/Divider/Divider';
import AssessorManagementTable from '../../../components/AssessorManagementTable/AssessorManagementTable';
import { IToastMessage } from '../../../interface';
import ToastMessage from '../../../components/ToastMessage/ToastMessage';

interface Props {
  className?: string;
}

function AssessorManagement({ className }: Props) {
  const [toastMessage, setToastMessage] = useState<IToastMessage>();

  return (
    <div className={
      classNames(
        className,
        style['main-content'],
        'AssessorManagement d-flex flex-column')
    }>
      {toastMessage?.visible && (
        <div className={style.toast}>
          <ToastMessage
            className='successful'
            status={toastMessage}
            visiableHandler={(value) =>
              setToastMessage({ ...toastMessage, visible: value })
            }
          />
        </div>
      )}

      <Divider className={style.divider1} />

      <AssessorManagementTable
        setToastMessage={setToastMessage}
      />

    </div>
  );
}

export default AssessorManagement;