import {AxiosRequestConfig, AxiosResponse} from 'axios';
import {FormikValues} from 'formik';
import _, {identity, fromPairs} from 'lodash';
import moment from 'moment';
import {apiInstance as Axios} from '../api/fetchData';
import {XLSXHEADER} from '../constants';
import {
  AssetClass,
  AssetField,
  GlobalAssetMapping,
  HelpCategory,
  HelpCategoryResponse,
  HelpCategoryTopic,
  PageTooltip,
  Property,
  PurchaseReturn,
  PurchaseReturnResponse,
  SubmitTopicVersion,
  ManageFormDetailData,
  FormQuestion,
  FormAttachment,
  TooltipItem,
  ReturnsItem,
  PurchaseReturnUI,
  MaintenanceNotification,
  Assessor,
  IBackendAssessorUpdate,
  FormGroup,
  DepreciationSchedule,
  IndexTable,
  DepreciationScheduleCollection,
  IBackendDepreciationScheduleCollectionUpdate,
  IBackendDepreciationScheduleUpdate,
  DepreciationScheduleFactor,
  IndexTableCollection,
  IBackendIndexTableCollectionUpdate,
  IBackendIndexTableUpdate,
  IndexTableFactor,
} from '../interface';
import {generateQueryString} from '../utils/generateQueryString';
import { IBackendTaxYear } from '../interface/IBackendTaxYear';
import { IBackendFormInfo, IBackendUpdateFormInfo } from '../interface/IBackendFormInfo';
import { IBackendFormMapping } from '../interface/IBackendFormMapping';

const getUserProfile = () => {
  return Axios.get('/auth/me');
};

const updateUserProfile = () => {
  return Axios.patch('/auth/me', {setupCompleted: true});
};

const getDashboardInfor = (taxYear: string) => {
  return Axios.get(`/dashboard?${generateQueryString({ taxYear })}`);
};

const getHelpCategories = ({title = ''} = {}) => {
  return Axios.get(`/helpCategories?${generateQueryString({title})}`);
};

const getStates = () => {
  return Axios.get('/states');
};

const addCompany = (value: FormikValues) => {
  return Axios.post('/companies', value);
};

const getCompanies = () => {
  return Axios.get('/companies?page=0&onlyActive=true');
};

const addLegalEntities = (value: FormikValues) => {
  return Axios.post('/legalEntities', value);
};

const getDownloadTemplate = () => {
  return Axios.get(
    '/properties/downloadTemplate',
    XLSXHEADER as AxiosRequestConfig,
  );
};

const getUploadedFile = ({
  type = '',
  page = 1,
  perPage = 20,
  sortBy = '',
  sortDirection = '',
} = {}) => {
  return Axios.get(
    `/uploadedFiles?${generateQueryString({
      type,
      page,
      perPage,
      sortBy,
      sortDirection,
    })}`,
  );
};

const getProperties = ({
  page = 0,
  perPage = 20,
  sortBy = 'name',
  sortDirection = 'asc',
  onlyActive = true,
  text = '',
  companyIds = '',
  legalEntityIds = '',
  assessorIds = '',
} = {}) => {
  return Axios.get(
    `/properties?${generateQueryString({
      page,
      perPage,
      sortBy,
      sortDirection,
      onlyActive,
      text,
      companyIds,
      legalEntityIds,
      assessorIds,
    })}`,
  );
};

const getAssessors = ({
  page = 0,
  perPage = 10,
  stateId = 0,
  sortBy = 'name',
  sortDirection = 'asc',
  text = '',
  ...otherProps
}: any = {}) => {
  return Axios.get<{
    page: number;
    perPage: number;
    total: number;
    items: Assessor[];
  }>(
    `/assessors?${generateQueryString({
      page,
      perPage: perPage,
      sortBy,
      sortDirection: sortBy && sortDirection,
      text,
      stateId: stateId || '',
      ...otherProps
    })}`,
  );
};

const getAssessorsByAccount = ({page = 0, userAccountId = ''} = {}) => {
  return Axios.get(
    `/assessors?${generateQueryString({
      page,
      userAccountId,
    })}`,
  );
};

const getAssessor = (id: number) => {
  return Axios.get<Assessor>(`/assessors/${id}`);
};

const deleteAssessor = (id: number) => {
  return Axios.delete<Assessor>(`/assessors/${id}`);
};

const updateAssessor = (id: number, assessorInfo: IBackendAssessorUpdate) => {
  return Axios.patch(`/assessors/${id}`, {
    assessor: assessorInfo
  });
};

const createAssessor = (assessorInfo: IBackendAssessorUpdate) => {
  return Axios.post('/assessors', {
    assessor: assessorInfo
  });
};

const getLegalEntities = ({
  page = 0,
  onlyActive = true,
  companyId = 0,
} = {}) => {
  return Axios.get(
    `/legalEntities?${generateQueryString({
      page,
      onlyActive,
      companyId: companyId || '',
    })}`,
  );
};

const setProperties = (values: FormikValues) => {
  return Axios.post(`/properties`, values);
};

const uploadFile = ({
  file = new Blob(),
  type = 'Property',
  assetType = '',
} = {}) => {
  const bodyFormData = new FormData();
  bodyFormData.append('file', file);
  bodyFormData.append('type', type);
  assetType && bodyFormData.append('assetType', assetType);
  return Axios.post(`/uploadedFiles/upload`, bodyFormData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const getAllLegalEntities = ({page = 0, onlyActive = true} = {}) => {
  return Axios.get(
    `/legalEntities?${generateQueryString({
      page,
      onlyActive,
    })}`,
  );
};

const validateProperties = (properties: Property[]) => {
  return Axios.post(`/properties/validate`, {
    items: properties,
  });
};

const downloadUploadedFile = (id: number) => {
  return Axios.get(
    `/uploadedFiles/${id}/download`,
    XLSXHEADER as AxiosRequestConfig,
  );
};

const deleteUploadedFile = (id: number) => {
  return Axios.delete(`/uploadedFiles/${id}`);
};

const getProperty = (id: string) => {
  return Axios.get(`/properties/${id}`);
};

const updateProperty = (id: string, values: FormikValues) => {
  return Axios.put(`/properties/${id}`, values);
};

const lookupAssessors = (ids: string) => {
  return Axios.get(`/assessors?page=0&stateIds=${ids}`);
};

const uploadProperty = (values: FormikValues) => {
  return Axios.post(`/properties`, values);
};

const deleteProperty = (id: number) => {
  return Axios.delete(`/properties/${id}`);
};

const getAssetsTemplate = (type: string) => {
  return Axios.get(
    `/assets/downloadTemplate?type=${type}`,
    XLSXHEADER as AxiosRequestConfig,
  );
};

const getPurchasedReturns = ({
  page = 1,
  perPage = 10,
  sortBy = '',
  sortDirection = '',
  text = '',
} = {}) => {
  return Axios.get<PurchaseReturnResponse>(
    `/userAccounts?${generateQueryString({
      page,
      perPage,
      sortBy,
      sortDirection: sortBy && sortDirection,
      text,
    })}`,
  );
};

const getPurchasedReturn = (id: number) => {
  return Axios.get(`/userAccounts/${id}`);
};

const addPurchasedReturns = (data: PurchaseReturn) => {
  return Axios.post<PurchaseReturn>('/userAccounts', data);
};

const updatePurchasedReturns = (id: number, data: PurchaseReturnUI) => {
  return Axios.put<PurchaseReturn>(`/userAccounts/${id}`, data);
};

const deletePurchasedReturns = (id: number) => {
  return Axios.delete(`/userAccounts/${id}`);
};

const editPurchasedReturns = (data: PurchaseReturn) => {
  return Promise.resolve({
    data: data,
    status: 200,
    config: {},
    statusText: '',
    headers: {},
  });
};

const getPages = () => {
  return Axios.get<string[]>('/toolTips/pages');
};

const getTooltips = (page: string) => {
  return Axios.get<PageTooltip[]>(`/toolTips?${generateQueryString({page})}`);
};

const updateTooltips = (data: TooltipItem[]) => {
  return Axios.put<PageTooltip[]>('/toolTips', {items: data});
};

const getHelpContents = () => {
  return Axios.get<HelpCategoryResponse>('/helpCategories?page=0');
};

const getTopic = (id: number) => {
  return Axios.get<HelpCategoryTopic>(`/helpTopics/${id}`);
};

const updateHelpCategory = (id: number, title: string) => {
  return Axios.put<HelpCategory>(`/helpCategories/${id}`, {
    title,
  });
};

const getHelpCategory = (id: number) => {
  return Axios.get<HelpCategory>(`/helpCategories/${id}`);
};

const createHelpCategory = (data: {
  title: string;
  topics: [{versions: SubmitTopicVersion[]}];
}) => {
  return Axios.post<HelpCategory>(`/helpCategories`, data);
};

const updateHelpTopic = (
  id: number,
  data: {
    helpCategoryId: number,
    versions: SubmitTopicVersion[]
  },
) => {
  return Axios.put<HelpCategoryTopic>(`/helpTopics/${id}`, data);
};

const addHelpTopic = (data: {
  helpCategoryId: number;
  versions: SubmitTopicVersion[];
}) => {
  return Axios.post<HelpCategoryTopic>(`/helpTopics`, data);
};

const deleteHelpTopic = (id: number) => {
  return Axios.delete(`/helpTopics/${id}`);
};

const deleteHelpCategory = (id: number) => {
  return Axios.delete(`/helpCategories/${id}`);
};

const reorderHelpCategory = (items: {id: number; displayOrder: number}[]) => {
  return Axios.put(`/helpCategories/displayOrders`, {
    items,
  });
};

const reorderHelpTopic = (items: {id: number; displayOrder: number}[]) => {
  return Axios.put(`/helpTopics/displayOrders`, {
    items,
  });
};

const getHelpTopicVersionContent = (s3PresignedUrl: string) => {
  return Axios.get(s3PresignedUrl, { transformRequest: (data, headers) => {
    delete headers['Authorization'];
    return data;
  }});
}

const getAllProperties = () => {
  return Axios.get(`/properties?page=0&onlyActive=true`);
};

const validateAssets = (assets: AssetField[]) => {
  return Axios.post(`/assets/validate`, {
    items: assets,
  });
};

const uploadAsset = (values: FormikValues) => {
  return Axios.post(`/assets`, values);
};

const getAllAssets = ({
  page = 1,
  perPage = 20,
  sortBy = 'company.name',
  sortDirection = 'asc',
  text = '',
  statuses = '',
  rolloverStatuses = '',
  companyIds = '',
  legalEntityIds = '',
  stateIds = '',
  propertyIds = '',
  assessorIds = '',
  assessorAccountIds = '',
  accountIds = '',
  taxYears = '',
  types = '',
  assetClassIds = '',
  additionStatuses = '',
  excludeDisposals = false,
  excludeRolledOver = false,
} = {}) => {
  return Axios.get(
    `/assets?${generateQueryString({
      page,
      perPage,
      sortBy,
      sortDirection,
      text,
      statuses,
      rolloverStatuses,
      companyIds,
      legalEntityIds,
      stateIds,
      propertyIds,
      assessorIds,
      assessorAccountIds,
      accountIds,
      taxYears,
      types,
      assetClassIds,
      additionStatuses,
      excludeDisposals,
      excludeRolledOver,
    })}`,
  );
};

const deleteAssets = (ids: string) => {
  return Axios.delete(
    `/assets?${generateQueryString({
      ids,
    })}`,
  );
};

const deleteAsset = (id: number) => {
  return Axios.delete(`/assets/${id}`);
};

const deleteReports = (ids: string) => {
  return Axios.delete(
    `/generalReports?${generateQueryString({
      ids,
    })}`,
  );
};

const deleteReport = (id: number) => {
  return Axios.delete(`/generalReports/${id}`);
};

const getAllAssessors = (taxYear: string) => {
  return Axios.get(
    `/assessoraccounts?page=0&onlyActive=true&sortBy=assessorAndAccountNumber&sortDirection=asc&taxYear=${taxYear}`,
  );
};

const getAllAssetClass = ({
  page = 0,
  perPage = 10,
  text = '',
  sortBy = '',
  sortDirection = '',
  status = '',
  assetClassIds = [] as number[],
  commonClassIds = [] as number[],
  taxYear = '',
} = {}) => {
  return Axios.get(
    `/assetClasses?${generateQueryString({
      page,
      perPage,
      text,
      sortBy,
      sortDirection,
      status,
      assetClassIds: assetClassIds.join(','),
      commonClassIds: commonClassIds.join(','),
      taxYear,
    })}`,
  );
};

const downloadCommonClasses = () =>
  Axios.get('/commonClasses/download', XLSXHEADER as AxiosRequestConfig);

const saveAssetClasses = (data: GlobalAssetMapping[]) => {
  return Axios.put<GlobalAssetMapping[]>(`/assetClasses`, {
    items: data.map((x) => ({id: x.id, commonClassId: x.commonClassId})),
  });
};

const getCommonAssetClass = () => {
  return Axios.get<AssetClass[]>('/commonClasses');
};

const setAssets = (values: FormikValues) => {
  return Axios.post(`/assets`, values);
};

const getAsset = (id: string) => {
  return Axios.get(`/assets/${id}`);
};

const updateAsset = (id: string, value: FormikValues) => {
  return Axios.put(`/assets/${id}`, value);
};

const bulkUpdateAssets = (ids: string, isAddition: boolean, isDisposal: boolean) => {
  return Axios.put(`/assets/bulkUpdate`, { ids, isAddition, isDisposal });
};

const updateUploadFile = (id: string, values: FormikValues = {}) => {
  return Axios.patch(`/uploadedFiles/${id}`, {
    columnMappings: values.columnMappings,
    items: values.items,
    status: values.status,
  });
};

const getUploadFile = (id: string) => {
  return Axios.get(`/uploadedFiles/${id}`);
};

const cancelUploadFile = (id: string) => {
  return Axios.patch(`/uploadedFiles/${id}`, {
    status: 'Cancelled',
  });
};

const setRollover = (ids: string) => {
  return Axios.post(
    `/assets/rollover?${generateQueryString({
      ids,
    })}`,
  );
};

const getAllUsers = ({
  page = 1,
  perPage = 10,
  sortBy = 'fullName',
  sortDirection = 'asc',
  text = '',
  roles = '',
  createdAtFrom = '',
  createdAtTo = '',
} = {}) => {
  return Axios.get(
    `/users?${generateQueryString({
      page,
      perPage,
      sortBy,
      sortDirection,
      text,
      roles,
      createdAtFrom,
      createdAtTo,
    })}`,
  );
};

const createUser = (value: FormikValues) => {
  return Axios.post('/users', value);
};

const updateUser = (id: string, value: FormikValues) => {
  return Axios.put(`/users/${id}`, value);
};

const getUserDetail = (id: string) => {
  return Axios.get(`/users/${id}`);
};

const deleteUser = (id: number) => {
  return Axios.delete(`/users/${id}`);
};

const deleteUsers = (ids: string[]) => {
  return Axios.delete(`/users?ids${ids.join(',')}`);
};

const getUserPurchases = ({
  page = 0,
  sortBy = 'createdAt',
  sortDirection = 'asc',
} = {}) => {
  return Axios.get(
    `/userPurchases?${generateQueryString({
      page,
      sortBy,
      sortDirection,
    })}`,
  );
};

const purchaseReturn = () => {
  return Axios.post('/supportCases', {
    category: 'Purchase Return',
  });
};

const getAccountSummary = () => {
  return Axios.get(`/userAccountSummary`);
};

const deleteCompany = (id: number) => {
  return Axios.delete(`/companies/${id}`);
};

const deleteLegalEntity = (id: number) => {
  return Axios.delete(`/legalEntities/${id}`);
};

const deleteAccount = (id: number) => {
  return Axios.delete(`/assessoraccounts/${id}`);
};

const getCompany = (id: string) => {
  return Axios.get(`/companies/${id}`);
};

const getLegalEntity = (id: string) => {
  return Axios.get(`/legalEntities/${id}`);
};

const updateCompany = (id: string, value: FormikValues) => {
  return Axios.put(`/companies/${id}`, value);
};

const updateLegalEntity = (id: string, value: FormikValues) => {
  return Axios.put(`/legalEntities/${id}`, value);
};

const getFormList = ({
  page = 1,
  perPage = 10,
  sortBy = 'statusStateAndForm',
  sortDirection = '',
  text = '',
  status = '',
  taxYear = '',
  stateId = '',
} = {}) => {
  return Axios.get(
    `/customerForms?${generateQueryString({
      page,
      perPage,
      sortBy,
      sortDirection,
      text,
      status,
      taxYear,
      stateId,
    })}`,
  );
};

const getFromDetail = (id: string | number) => {
  return Axios.get<ManageFormDetailData>(`/customerForms/${id}`);
};

const getFromQuestions = (formId: number) => {
  return Axios.get<FormQuestion[]>(`/forms/${formId}/formQuestions`);
};

// eslint-disable-next-line
const saveFromAnswers = (id: string | number, data: any) => {
  return Axios.put(`/customerForms/${id}/customerFormAnswers`, data);
};

const getFromAttachments = (formId: number) => {
  return Axios.get<FormAttachment[]>(`/forms/${formId}/formAttachments`);
};

const getReturns = (
  {
    page = 1,
    perPage = 10,
    sortBy = '',
    sortDirection = 'asc',
    statuses = '',
    dateRange = [] as string[],
    taxYear = '',
    assessorIds = '',
    stateIds = '',
    text = '',
    includeReturnsWithNoAssets = false
  } = {},
  signal: AbortSignal | undefined = undefined,
) => {
  return Axios.get(
    `/returns?${generateQueryString({
      page,
      perPage,
      sortBy,
      sortDirection: sortBy && sortDirection,
      statuses,
      taxYear,
      assessorIds,
      stateIds,
      text,
      includeReturnsWithNoAssets,
      ..._(
        fromPairs([
          ['assessorReturnsDeadlineFrom', dateRange?.[0]],
          ['assessorReturnsDeadlineTo', dateRange?.[1]],
        ]),
      )
        .pickBy(identity)
        .mapValues((x) => moment(x).format('YYYY-MM-DD'))
        .value(),
    })}`,
    {signal: signal},
  );
};

const getGeneralReports = ({
  page = 1,
  perPage = 10,
  sortBy = '',
  sortDirection = 'asc',
  text = '',
  dateRange = [] as string[],
} = {}) => {
  return Axios.get(
    `/generalReports?${generateQueryString({
      page,
      perPage,
      sortBy,
      sortDirection: sortBy && sortDirection,
      text,
      ..._(
        fromPairs([
          ['createdAtFrom', dateRange?.[0]],
          ['createdAtTo', dateRange?.[1]],
        ]),
      )
        .pickBy(identity)
        .mapValues((x) => moment(x).format('YYYY-MM-DD'))
        .value(),
    })}`,
  );
};

const createGeneralReport = (value: FormikValues) => {
  return Axios.post(`/generalReports`, value);
};

const downloadGeneralReport = (id: string) => {
  return Axios.get(`/generalReports/${id}/download`, {
    responseType: 'blob',
  });
};

const downloadImage = (id: string | number) => {
  return Axios.get<ArrayBuffer>(`/documents/${id}/download`, {
    responseType: 'arraybuffer',
  });
};

const downloadDocument = (id: string) => {
  return Axios.get(`/documents/${id}/download`, {
    responseType: 'blob',
  });
};

// eslint-disable-next-line
const saveFromAttachments = (id: string, data: any) => {
  return Axios.put(`/customerForms/${id}/customerFormAttachments`, data);
};

const getReturnFormAttachment = (returnId: number, attachmentId: number) => {
  return Axios.get<ArrayBuffer>(
    `/returns/${returnId}/customerFormAttachments/${attachmentId}/preview`,
    {responseType: 'arraybuffer'},
  );
};

const getCustomerReturnForm = (returnId: number) => {
  return Axios.get<ManageFormDetailData>(`/returns/${returnId}/customerForm`);
};

const getReturnDetail = (id: string) => {
  return Axios.get<ReturnsItem>(`/returns/${id}`);
};

const getAssertMappings = (
  id: string,
  {
    page = 1,
    perPage = 10,
    sortBy = '',
    sortDirection = 'asc',
    text = '',
    statuses = '',
    types = '',
    assetClassIds = '',
    commonClassIds = '',
    formGroupIds = '',
    depreciationScheduleIds = '',
    indexTableIds = '',
    assetIds = '',
    taxables = '',
    reportables = '',
    disposedStatuses = '',
    additionStatuses = '',
    acquisitionDateRange = [] as string[],
    ids = '',
  } = {},
) => {
  return Axios.get(
    `/returns/${id}/assetMappings?${generateQueryString({
      page,
      perPage,
      sortBy,
      sortDirection: sortBy && sortDirection,
      text,
      statuses,
      types,
      assetClassIds,
      commonClassIds,
      formGroupIds,
      depreciationScheduleIds,
      indexTableIds,
      assetIds,
      taxables,
      reportables,
      disposedStatuses,
      additionStatuses,
      ..._(
        fromPairs([
          ['acquisitionDateFrom', acquisitionDateRange?.[0]],
          ['acquisitionDateTo', acquisitionDateRange?.[1]],
        ]),
      )
        .pickBy(identity)
        .mapValues((x) => moment(x).format('YYYY-MM-DD'))
        .value(),
      ids,
    })}`,
  );
};

const deleteAssetMappings = (id: string, assetMappingIds: string) => {
  return Axios.delete(`/returns/${id}/assetMappings?ids=${assetMappingIds}`);
};

const approveAssetMappings = (id: string, assetMappingIds: string) => {
  return Axios.put(
    `/returns/${id}/assetMappings/approve?ids=${assetMappingIds}`,
  );
};

const unapproveAssetMappings = (id: string, assetMappingIds: string) => {
  return Axios.put(
    `/returns/${id}/assetMappings/unapprove?ids=${assetMappingIds}`,
  );
};

const approveAsset = (id: number) => {
  return Axios.put<ReturnsItem>(`/returns/${id}/approve`);
};

const approveBulkAsset = (ids: number[]) => {
  return Axios.put<ReturnsItem[]>(`/returns/approve?ids=${ids.join(',')}`);
};

const unapproveAsset = (id: number) => {
  return Axios.put<ReturnsItem>(`/returns/${id}/unapprove`);
};

const markAssetAsFilled = (id: number) => {
  return Axios.put<ReturnsItem>(`/returns/${id}/markAsFiled`);
};

const bulkMarkReturnAsFiled = (ids: number[]) => {
  return Axios.put<ReturnsItem[]>(`/returns/bulkMarkAsFiled`, { 
    ids: ids.join(','),
    mergePdfs: false
  });
};

const unfileReturn = (id: number) => {
  return Axios.put<ReturnsItem>(`/returns/${id}/unmarkAsFiled`);
};

const downloadFiledReturnPackage = (id: number) => {
  return Axios.get(`/returns/${id}/downloadFiledReturnPackage`, {
    responseType: 'blob',
  });
};

const bulkDownloadFiledReturnPackage = ({
  ids = '',
  mergePdfs = false,
  } = {}) => {
  return Axios.get(`/returns/bulkDownloadFiledReturnPackage?mergePdfs=${mergePdfs}&${generateQueryString({ ids })}`, { responseType: 'arraybuffer' });
};

const getPreviewForm = (id: number) => {
  return Axios.get<ArrayBuffer>(`/returns/${id}/previewForm`, {
    responseType: 'arraybuffer',
  });
};

const getReturnCustomerForm = (id: number) => {
  return Axios.get<ManageFormDetailData>(`/returns/${id}/customerForm`);
};

const getReturnForms = ({
  page = 1,
  stateId = null,
  taxYear = null,
}: {
  page: number;
  stateId: number | null;
  taxYear: number | null;
}) => {
  return Axios.get('/forms', {
    params: {
      page,
      stateId,
      taxYear,
    },
  });
};

const getFormInfo = (
  formId: number
) => {
  return Axios.get<IBackendFormInfo>(`/forms/${formId}`);
};

const updateFormInfo = (
  formId: number,
  data: IBackendUpdateFormInfo
) => {
  return Axios.put(`/forms/${formId}`, data);
};

const updateMappingInfo = (
  datas: IBackendFormMapping[]
) => {
  return Axios.put('/mappings', {
    mappings: datas
  });
};

const getMappingInfos = (
  query: {
    assessorId?: number | null;
    stateId?: number | null;
    taxYear?: number | null;
    formId?: number | null;
    depreciationScheduleCollectionId?: number | null;
    indexTableCollectionId?: number | null;
  }
) => {
  return Axios.get<IBackendFormMapping[]>('/mappings', {
    params: query,
  });
};

const getDepreciationScheduleCollections = ({
  page = 1,
  perPage = 20,
  sortBy = 'name',
  sortDirection = 'asc',
  text = '',
  stateIds = '',
  type = '',
  taxYear = null,
} : {
  page: number;
  perPage?: number;
  sortBy?: string;
  sortDirection?: string;
  text?: string;
  stateIds: string;
  type?: string;
  taxYear?: number | null;
}) => {
  return Axios.get<{
    page: number;
    perPage: number;
    total: number;
    items: DepreciationScheduleCollection[];
  }>('/depreciationScheduleCollections', {
    params: {
      page,
      perPage,
      sortBy,
      sortDirection,
      text,
      stateIds,
      type,
      taxYear,
    },
  });
};

const getDepreciationScheduleCollection = (id: number) => {
  return Axios.get<DepreciationScheduleCollection>(`/depreciationScheduleCollections/${id}`);
};

const updateDepreciationScheduleCollection = (id: number, depreciationCollectionInfo: IBackendDepreciationScheduleCollectionUpdate) => {
  return Axios.patch(`/depreciationScheduleCollections/${id}`, {
    depreciationScheduleCollection: depreciationCollectionInfo
  });
};

const createDepreciationScheduleCollection = (depreciationCollectionInfo: IBackendDepreciationScheduleCollectionUpdate) => {
  return Axios.post('/depreciationScheduleCollections', {
    depreciationScheduleCollection: depreciationCollectionInfo
  });
};

const getIndexTableCollections = ({
  page = 1,
  perPage = 20,
  sortBy = 'name',
  sortDirection = 'asc',
  text = '',
  stateIds = '',
  taxYear = null,
} : {
  page: number;
  perPage?: number;
  sortBy?: string;
  sortDirection?: string;
  text?: string;
  stateIds: string;
  taxYear?: number | null;
}) => {
  return Axios.get<{
    page: number;
    perPage: number;
    total: number;
    items: DepreciationScheduleCollection[];
  }>('/indexTableCollections', {
    params: {
      page,
      perPage,
      sortBy,
      sortDirection,
      text,
      stateIds,
      taxYear,
    },
  });
};

const getIndexTableCollection = (id: number) => {
  return Axios.get<IndexTableCollection>(`/indexTableCollections/${id}`);
};

const updateIndexTableCollection = (id: number, indexCollectionInfo: IBackendIndexTableCollectionUpdate) => {
  return Axios.patch(`/indexTableCollections/${id}`, {
    indexTableCollection: indexCollectionInfo
  });
};

const createIndexTableCollection = (indexCollectionInfo: IBackendIndexTableCollectionUpdate) => {
  return Axios.post('/indexTableCollections', {
    indexTableCollection: indexCollectionInfo
  });
};

const deleteIndexTableCollection = (id: number) => {
  return Axios.delete(`/indexTableCollections/${id}`);
};

const updateReturnForm = (
  returnId: number,
  {
    formId,
  }: {
    formId: number;
  },
) => {
  return Axios.patch(`/returns/${returnId}`, {
    formId,
  });
};

const updateReturnDepreciationScheduleCollection = (
  returnId: number,
  {
    depreciationScheduleCollectionId,
  }: {
    depreciationScheduleCollectionId: number;
  },
) => {
  return Axios.patch(`/returns/${returnId}`, {
    depreciationScheduleCollectionId,
  });
};

const updateReturnIndexTable = (
  returnId: number,
  {
    indexTableCollectionId,
  }: {
    indexTableCollectionId: number;
  },
) => {
  return Axios.patch(`/returns/${returnId}`, {
    indexTableCollectionId,
  });
};

const getAvalaraStandardAssetClasses = () => {
  return Axios.get('/commonClasses');
};

const getFormGroups = ({formId, page = 0}: {formId: number; page?: number}) => {
  return Axios.get<{
    items: FormGroup[]
  }>('/formGroups', {
    params: {
      formId,
      page,
    },
  });
};

const getDepreciationSchedules = ({
  depreciationScheduleCollectionId,
  page = 1,
  perPage = 20,
  sortBy = 'name',
  sortDirection = 'asc',
}: {
  depreciationScheduleCollectionId: number;
  page?: number;
  perPage?: number;
  sortBy?: string;
  sortDirection?: string;
}) => {
  return Axios.get<{
    page: number;
    perPage: number;
    total: number;
    items: DepreciationSchedule[];
  }>('/depreciationSchedules', {
    params: {
      depreciationScheduleCollectionId,
      page,
      perPage,
      sortBy,
      sortDirection,
    },
  });
};

const getDepreciationSchedule = (id: number) => {
  return Axios.get<DepreciationSchedule>(`/depreciationSchedules/${id}`);
};

const updateDepreciationSchedule = (id: number, depreciationScheduleInfo: IBackendDepreciationScheduleUpdate) => {
  return Axios.patch(`/depreciationSchedules/${id}`, {
    depreciationSchedule: depreciationScheduleInfo
  });
};

const createDepreciationSchedule = (depreciationScheduleInfo: IBackendDepreciationScheduleUpdate) => {
  return Axios.post('/depreciationSchedules', {
    depreciationSchedule: depreciationScheduleInfo
  });
};

const deleteDepreciationSchedule = (id: number) => {
  return Axios.delete(`/depreciationSchedules/${id}`);
};

const deleteDepreciationScheduleCollection = (id: number) => {
  return Axios.delete(`/depreciationScheduleCollections/${id}`);
};

const bulkUpdateDepreciationFactors = (depreciationScheduleId: number, factors: DepreciationScheduleFactor[]) => {
  return Axios.patch(`/depreciationSchedules/${depreciationScheduleId}/factors`, { factors });
};

const getIndexTables = ({
  indexTableCollectionId,
  page = 1,
  perPage = 20,
  sortBy = 'name',
  sortDirection = 'asc',
}: {
  indexTableCollectionId: number;
  page?: number;
  perPage?: number;
  sortBy?: string;
  sortDirection?: string;
}) => {
  return Axios.get<{
    page: number;
    perPage: number;
    total: number;
    items: IndexTable[];
  }>('/indexTables', {
    params: {
      indexTableCollectionId,
      page,
      perPage,
      sortBy,
      sortDirection,
    },
  });
};

const getIndexTable = (id: number) => {
  return Axios.get<IndexTable>(`/indexTables/${id}`);
};

const updateIndexTable = (id: number, indexTableInfo: IBackendIndexTableUpdate) => {
  return Axios.patch(`/indexTables/${id}`, {
    indexTable: indexTableInfo
  });
};

const createIndexTable = (indexTableInfo: IBackendIndexTableUpdate) => {
  return Axios.post('/indexTables', {
    indexTable: indexTableInfo
  });
};

const deleteIndexTable = (id: number) => {
  return Axios.delete(`/indexTables/${id}`);
};

const bulkUpdateIndexFactors = (indexTableId: number, factors: IndexTableFactor[]) => {
  return Axios.patch(`/indexTables/${indexTableId}/factors`, { factors });
};

const updateAssetMappings = (
  returnId: number,
  ids: number[],
  payload: {
    commonClassId?: number;
    taxable?: boolean | null;
    formGroupId?: number | null;
    depreciationScheduleId?: number | null;
    indexTableId?: number | null;
    reportable?: boolean;
    isAddition?: boolean;
    disposed?: boolean;
  },
) => {
  return Axios.patch(`/returns/${returnId}/assetMappings`, payload, {
    params: {
      ids: ids.join(','),
    },
  });
};

const getDefaultMappings = (returnId: number, commonClassId: number) => {
  return Axios.get(`/returns/${returnId}/defaultMappings`, {
    params: {
      commonClassId,
    },
  });
};

const getHealthcheck = () => {
  return Axios.get('/auth/healthcheck');
};

const getSystemTaxYears = ( onlyActive = true ) => {
  return Axios.get<IBackendTaxYear[]>(
    `/systemtaxyears/search?${generateQueryString({ onlyActive })}`);
};

const getMaintenanceNotifications = ({
  page = 1,
  perPage = 10,
  sortBy = '',
  sortDirection = '',
  text = '',
  onlyActive = true
} = {}) => {
  return Axios.get(
    `/maintenancenotifications?${generateQueryString({
      page,
      perPage,
      sortBy,
      sortDirection,
      text,
      onlyActive
    })}`,
  );
};

const getMaintenanceNotification = (id: number) => {
  return Axios.get(`/maintenancenotifications/${id}`);
};

const addMaintenanceNotifications = (data: MaintenanceNotification) => {
  return Axios.post<MaintenanceNotification>('/maintenancenotifications', data);
};

const updateMaintenanceNotifications = (id: number, data: MaintenanceNotification) => {
  return Axios.put<MaintenanceNotification>(`/maintenancenotifications/${id}`, data);
};

const deleteMaintenanceNotifications = (id: number) => {
  return Axios.delete(`/maintenancenotifications/${id}`);
};

const editMaintenanceNotifications = (data: MaintenanceNotification) => {
  return Promise.resolve({
    data: data,
    status: 200,
    config: {},
    statusText: '',
    headers: {},
  });
};

export const DEFAULT_ERROR_MESSAGE = 'An error occurred, please try again later.';
//eslint-disable-next-line
const getErrorMessage = (response: AxiosResponse<any>): string => {
  const error = _.get(response, 'data.errors[0]', null);
  if (error) {
    return error.replace(/items\.\d\./, '');
  }
  const status: number = _.get(response, 'status', 0);
  if (_.inRange(status, 200, 299)) {
    return '';
  }
  const errorMessage = _.get(response, 'data.message', null);
  if (errorMessage) {
    return errorMessage.replace(/items\.\d\./, '');
  }

  return DEFAULT_ERROR_MESSAGE;
};

export {
  getUserProfile,
  updateUserProfile,
  getDashboardInfor,
  getHelpCategories,
  getStates,
  addCompany,
  getCompanies,
  addLegalEntities,
  getDownloadTemplate,
  getUploadedFile,
  getProperties,
  getAssessors,
  getAssessorsByAccount,
  getAssessor,
  deleteAssessor,
  updateAssessor,
  createAssessor,
  getLegalEntities,
  setProperties,
  uploadFile,
  getAllLegalEntities,
  validateProperties,
  downloadUploadedFile,
  deleteUploadedFile,
  getProperty,
  updateProperty,
  lookupAssessors,
  uploadProperty,
  getTopic,
  deleteProperty,
  getAssetsTemplate,
  getPurchasedReturns,
  getPurchasedReturn,
  addPurchasedReturns,
  editPurchasedReturns,
  getPages,
  getTooltips,
  updateTooltips,
  getHelpContents,
  updateHelpCategory,
  getAllProperties,
  validateAssets,
  uploadAsset,
  getAllAssets,
  deleteAssets,
  deleteAsset,
  deleteReports,
  deleteReport,
  getAllAssessors,
  getAllAssetClass,
  setAssets,
  getAsset,
  updateAsset,
  bulkUpdateAssets,
  updateUploadFile,
  getUploadFile,
  cancelUploadFile,
  setRollover,
  getAllUsers,
  createUser,
  updateUser,
  getUserDetail,
  deleteUser,
  deleteUsers,
  getUserPurchases,
  purchaseReturn,
  getAccountSummary,
  deleteCompany,
  deleteLegalEntity,
  deleteAccount,
  getCompany,
  getLegalEntity,
  updateCompany,
  updateLegalEntity,
  updateHelpTopic,
  addHelpTopic,
  createHelpCategory,
  deleteHelpTopic,
  deleteHelpCategory,
  reorderHelpTopic,
  getHelpTopicVersionContent,
  reorderHelpCategory,
  updatePurchasedReturns,
  deletePurchasedReturns,
  getCommonAssetClass,
  saveAssetClasses,
  getFormList,
  getFromDetail,
  saveFromAnswers,
  downloadCommonClasses,
  downloadImage,
  downloadDocument,
  saveFromAttachments,
  getHelpCategory,
  getFromQuestions,
  getFromAttachments,
  getReturns,
  getGeneralReports,
  createGeneralReport,
  downloadGeneralReport,
  getReturnDetail,
  getAssertMappings,
  deleteAssetMappings,
  approveAssetMappings,
  unapproveAssetMappings,
  approveAsset,
  approveBulkAsset,
  markAssetAsFilled,
  bulkMarkReturnAsFiled,
  downloadFiledReturnPackage,
  bulkDownloadFiledReturnPackage,
  unapproveAsset,
  unfileReturn,
  getPreviewForm,
  getReturnCustomerForm,
  getReturnFormAttachment,
  getCustomerReturnForm,
  getReturnForms,
  getFormInfo,
  updateFormInfo,
  updateMappingInfo,
  getMappingInfos,
  getDepreciationScheduleCollections,
  getDepreciationScheduleCollection,
  createDepreciationScheduleCollection,
  updateDepreciationScheduleCollection,
  deleteDepreciationScheduleCollection,
  getIndexTableCollections,
  getIndexTableCollection,
  createIndexTableCollection,
  updateIndexTableCollection,
  deleteIndexTableCollection,
  updateReturnForm,
  updateReturnDepreciationScheduleCollection,
  updateReturnIndexTable,
  getAvalaraStandardAssetClasses,
  getFormGroups,
  getDepreciationSchedules,
  getDepreciationSchedule,
  updateDepreciationSchedule,
  createDepreciationSchedule,
  deleteDepreciationSchedule,
  bulkUpdateDepreciationFactors,
  getIndexTables,
  getIndexTable,
  updateIndexTable,
  createIndexTable,
  deleteIndexTable,
  bulkUpdateIndexFactors,
  updateAssetMappings,
  getDefaultMappings,
  getErrorMessage,
  getHealthcheck,
  getSystemTaxYears,
  getMaintenanceNotifications,
  getMaintenanceNotification,
  addMaintenanceNotifications,
  updateMaintenanceNotifications,
  deleteMaintenanceNotifications,
  editMaintenanceNotifications
};
